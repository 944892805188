import '../scss/style.scss';

window.$ = window.jQuery = require('jquery');

require('./libs/slick');
require('./libs/css3-animate-it');
require('./libs/scrollbar');
require('./sticky-header');
require('./courses-slider');
require('./video-modal');
require('./animated.js');