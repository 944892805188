// -----------------------------------------------------------------------------
// Scripts for animated
// -----------------------------------------------------------------------------

$(function () {

  var $animationElements = $('.animated');

  function check_if_in_view() {
    var $window = $(window);
    var windowHeight = $window.height();
    var windowTopPosition = $window.scrollTop();
    var windowBottomPosition = (windowTopPosition + windowHeight);

    $.each($animationElements, function () {
      var $element = $(this);
      var elementHeight = $element.outerHeight();
      var elementTopPosition = $element.offset().top + 0;
      var elementBottomPosition = (elementTopPosition + elementHeight);

      //check to see if this current container is within viewport
      if ((elementBottomPosition >= windowTopPosition) &&
        (elementTopPosition <= windowBottomPosition) && !$element.hasClass('go')) {
        $element.addClass('go');//.removeClass('animated_delay');
      }
    });
  }

  $(window).on('scroll', function () {
    check_if_in_view();
  });
  $(window).trigger('scroll');

});

