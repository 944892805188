// -----------------------------------------------------------------------------
// Scripts for courses slider
// -----------------------------------------------------------------------------

$(function () {
  $('#coursesSlider').slick({
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });

  // Custom navigation
  $('.slide-arrow--prev').click(function () {
    $('#coursesSlider').slick('slickPrev');
  });

  $('.slide-arrow--next').click(function () {
    $('#coursesSlider').slick('slickNext');
  });
});