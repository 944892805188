// -----------------------------------------------------------------------------
// Scripts for video modal
// -----------------------------------------------------------------------------

$(function () {
  $('.courses__video').on('click',function () {
    $('#videoModal').fadeIn(300);
    $('body').addClass('no-scroll');
  });

  $('.video-modal__btn, .video-modal__wrapper').on('click',function () {
    $('#videoModal').fadeOut(300);
    $('body').removeClass('no-scroll');
  });

  $('.video-modal__scrollbar').scrollbar();
});